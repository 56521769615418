<template>
  <div class="row">
    <div class="col">
      <card>
        <h6 class="d-inline-block pt-3 text-primary">{{ facility.name }} Members</h6>
        <div class="row my-2">
          <div class="col-sm-8 col-md-6 col-lg-4">
            <fg-input class="no-border"
                      label="Search For Member by Last Name"
                      type="text"
                      v-model="searchCriteria"/>
          </div>
          <div class="col-sm-4 col-md-6 col-lg-8">
            <n-button round type="danger" @click.native="clearSearch()" class="ml-2 mt-4">
              <i class="fas fa-times mr-2"></i> Clear
            </n-button>
          </div>
        </div>

        <table class="results table">
          <thead>
          <tr>
            <th style="width: 25%">Name</th>
            <th style="width: 65px" class="text-center">Init</th>
            <th>Email</th>
            <th style="width: 115px">Cell</th>
            <th style="width: 75px" class="text-center">Type</th>
            <th>Role</th>
            <th>Area</th>
          </tr>
          </thead>

          <tr v-for="member in sortedMembers" :class="{ 'bg-lightgray text-info': member.deleted_at, 'bg-lightblue': !member.init }" :key="member.id">
            <td>
              {{ member.name_lf }}

              <div class="float-right">
                <el-tooltip content="BUE is a FacRep or VP" v-if="member.position">
                  <i class="fas fa-person-circle-check text-success mr-2"/>
                </el-tooltip>

                <edit-member :member="member"
                             @update-member="updateMember(member, $event)"
                             class="mr-2"></edit-member>

                <el-tooltip content="Restore the deleted member" v-if="member.deleted_at">
                  <a href="#" @click="undelete(member.id)">
                    <i class="fas fa-trash-arrow-up text-success" />
                  </a>
                </el-tooltip>
              </div>
            </td>
            <td class="text-center text-uppercase">
              <inline-input v-model="member.init"
                            @blur="updateMember(member)"
                            container-class="d-block w-100 h-100"
                            maxlength="2"
                            input-class="w-100 text-center text-uppercase"/>
            </td>
            <td>{{ member.email }}</td>
            <td>{{ member.cell_phone }}</td>
            <td class="text-center">
              {{ employeeType(member.employee_type_id) ? employeeType(member.employee_type_id).type_tag : '' | uppercase }}
            </td>
            <td>
              <span v-if="!member.user_id">
                  <i class="fas fa-times fa-lg text-danger"></i>
                  No Account
              </span>
              <span v-else>
                    {{ roleName(member.role_id) }}
                </span>
            </td>
            <td>
              {{ areaName(member.area_id) ? areaName(member.area_id).name : '' | uppercase }}
            </td>
          </tr>
        </table>
      </card>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { EditMember } from '@/components'
import { InlineInput } from '@/components/stateless'
import { RepositoryFactory } from '@/repositories/RepositoryFactory'

const MemberRepository = RepositoryFactory.get('member')

export default {
  name: 'members',
  components: {
    InlineInput,
    EditMember
  },
  data () {
    return {
      members: [],
      searchCriteria: null,
      searchResults: [],
      positions: {
        facrep: 'FacRep',
        vp: 'VP'
      }
    }
  },
  methods: {
    /** Update the member with the new values */
    updateMember (member, update) {
      const i = _.findIndex(this.members, ['id', member.id])
      Object.assign(this.members[i], update)
    },
    /** Shortcut to clear search/filter criteria */
    clearSearch () {
      this.searchCriteria = null
    }
  },
  computed: {
    ...mapGetters({
      facility: 'facility/facility',
      employeeType: 'facility/employeeType',
      is: 'auth/is',
      roleName: 'auth/roleName',
      roleNames: 'auth/roleNames',
      areaName: 'area/name'
    }),
    sortedMembers () {
      // Filter members
      const members = this.searchCriteria
        ? this.members.filter(m => m.lname.toLowerCase().startsWith(this.searchCriteria.toLowerCase()))
        : this.members

      // Sort remaining members
      return _.sortBy(members, m => {
        return _.indexOf(Object.keys(this.roleNames), m.role_id) * -1
      })
    }
  },
  created () {
    MemberRepository.index()
      .then(r => {
        this.members = r.data
      })
  }
}
</script>
<style></style>
